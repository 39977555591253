import { Directive, ViewContainerRef, TemplateRef, Input } from '@angular/core';
import { AuthService } from '../auth/auth.service';


@Directive({
    selector: '[appCheckRole]'
})
export class CheckRoleDirective {
    @Input()
    set appCheckRole(roles: Array<string>) {
        const mustHide = !this.authService.isAuthenticated()
            || (roles.length > 0 &&
                roles.filter(x => this.authService.getUser().Roles.includes(x)).length === 0);
        if (mustHide) {
            this.viewContainer.clear();
        } else {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }

    constructor(
        private authService: AuthService,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
    ) { }
}
