import { ViewContainerRef, TemplateRef } from '@angular/core';
import { AuthService } from '../auth/auth.service';
var CheckRoleDirective = /** @class */ (function () {
    function CheckRoleDirective(authService, templateRef, viewContainer) {
        this.authService = authService;
        this.templateRef = templateRef;
        this.viewContainer = viewContainer;
    }
    Object.defineProperty(CheckRoleDirective.prototype, "appCheckRole", {
        set: function (roles) {
            var _this = this;
            var mustHide = !this.authService.isAuthenticated()
                || (roles.length > 0 &&
                    roles.filter(function (x) { return _this.authService.getUser().Roles.includes(x); }).length === 0);
            if (mustHide) {
                this.viewContainer.clear();
            }
            else {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
        },
        enumerable: true,
        configurable: true
    });
    return CheckRoleDirective;
}());
export { CheckRoleDirective };
