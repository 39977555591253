//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export class RegistraUtenteDto
{
	public Email: string;
	public ConfermaEmail: string;
	public Password: string;
	public ConfermaPassword: string;
	public Culture: string;
	public TimeZone: string;
	public EmailConfirmed: boolean;
	public Azienda: string;
	public Nome: string;
	public Cognome: string;
	public Ruolo: string;
	public Telefono: string;
	public Indirizzo: string;
	public Citta: string;
	public Provincia: string;
	public Paese: string;
	public Cap: string;
	public IdRivenditore: string;
}
