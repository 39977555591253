import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class RefreshTokenService {
  private RefreshTokenTimerMs = 10 * 60 * 1000;
  // private RefreshTokenTimerMs = 10000;

  constructor(
    private authService: AuthService) { }

  keepTokenAlive() {
    setInterval(() => {
      const authToken = this.authService.getToken();
      if (authToken) {
        this.authService.refreshToken(authToken.refresh_token)
          .subscribe(result => {
            this.authService.setToken(result);
          });
      }
    }, this.RefreshTokenTimerMs);
  }
}
