import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/shared/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IdRivenditori } from 'app/domain/IdRivenditori';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  isSubmitting = false;

  idRivenditore = '';
  email = '';
  password = '';
  recaptchaToken = '';

  constructor(private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.idRivenditore = this.authService.getIdRivenditore();
    if (!this.idRivenditore) {
      this.idRivenditore = this.route.snapshot.queryParams['ref'];
    }
    if (!this.idRivenditore) {
      this.idRivenditore = IdRivenditori.DvrTeamId;
    }
  }

  onSubmit() {
    this.isSubmitting = true;
    this.authService.login(this.email, this.password).subscribe(result => {
      this.isSubmitting = false;
      // console.log('login', result);
      this.authService.setToken(result);
      this.authService.refreshUser().subscribe(u => {
        this.authService.setUser(u);
        this.router.navigateByUrl('/');
      })
    }, err => {
      this.isSubmitting = false;
      this.toastr.error('Utente o password non validi');
    });
  }

  onForgotPassword() {
    this.router.navigate(['/forgotpassword']);
  }

  onRegister() {
    this.router.navigate(['/register']);
  }
}
