<section>
  <div class="container-fluid mt-5">
    <div class="row full-height-vh">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="card  text-center width-400">
          <div class="card-img overlap">
            <img class="mb-1 " src="assets/img/logos/logo-color-big.png" width="350">
          </div>
          <div class="card-body">
            <div class="card-block">
              <app-distributed-by [idRivenditore]="idRivenditore"></app-distributed-by>
              <app-validation-result [validationResult]="validationResult"></app-validation-result>
              <form novalidate (ngSubmit)="onSubmit()" #f="ngForm">
                <div class="form-group">
                  <div class="col-md-12">
                    <input type="text" class="form-control" name="inputNome" placeholder="Nome" [(ngModel)]="nome"
                      required #inputNome="ngModel">
                    <app-control-validation-result [control]="inputNome"></app-control-validation-result>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12">
                    <input type="text" class="form-control" name="inputCognome" placeholder="Cognome"
                      [(ngModel)]="cognome" required #inputCognome="ngModel">
                    <app-control-validation-result [control]="inputCognome"></app-control-validation-result>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12">
                    <input type="text" class="form-control" name="inputAzienda" placeholder="Azienda"
                      [(ngModel)]="azienda" required #inputAzienda="ngModel">
                    <app-control-validation-result [control]="inputAzienda"></app-control-validation-result>
                  </div>
                </div>
                <!-- <div class="form-group">
                  <div class="col-md-12">
                    <input type="text" class="form-control" name="inputRuolo" placeholder="Ruolo" [(ngModel)]="ruolo"
                      #inputRuolo="ngModel" required>
                    <app-control-validation-result [control]="inputRuolo"></app-control-validation-result>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12">
                    <input type="text" class="form-control" name="inputIndirizzo" placeholder="Indirizzo"
                      [(ngModel)]="indirizzo" #inputIndirizzo="ngModel" required>
                    <app-control-validation-result [control]="inputIndirizzo"></app-control-validation-result>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12">
                    <input type="text" class="form-control" name="inputCap" placeholder="Cap" [(ngModel)]="cap"
                      #inputCap="ngModel" required>
                    <app-control-validation-result [control]="inputCap"></app-control-validation-result>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12">
                    <input type="text" class="form-control" name="inputCitta" placeholder="Città" [(ngModel)]="citta"
                      #inputCitta="ngModel" required>
                    <app-control-validation-result [control]="inputCitta"></app-control-validation-result>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12">
                    <input type="text" class="form-control" name="inputProvincia" placeholder="Provincia"
                      [(ngModel)]="provincia" #inputProvincia="ngModel" required>
                    <app-control-validation-result [control]="inputProvincia"></app-control-validation-result>
                  </div>
                </div> -->
                <div class="form-group">
                  <div class="col-md-12">
                    <input type="email" class="form-control" name="inputEmail" placeholder="Email" [(ngModel)]="email"
                      #inputEmail="ngModel" required email>
                    <app-control-validation-result [control]="inputEmail"></app-control-validation-result>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12">
                    <input type="text" class="form-control" name="inputTelefono" placeholder="Telefono"
                      [(ngModel)]="telefono" #inputTelefono="ngModel" required>
                    <app-control-validation-result [control]="inputTelefono"></app-control-validation-result>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12">
                    <input type="password" class="form-control" name="inputPass" placeholder="Password"
                      [(ngModel)]="password" #inputPass="ngModel" required minlength="8">
                    <app-control-validation-result [control]="inputPass"></app-control-validation-result>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12">
                    <input type="checkbox" name="inputprivacy" #inputprivacy="ngModel" required [(ngModel)]="privacy">
                    Ho preso visione della
                    <a target="_blank" href="https://dvrsuite.it/privacy-cookies-policy">Privacy Policy</a>
                    <app-control-validation-result [control]="inputprivacy"></app-control-validation-result>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12">
                    <input type="checkbox" name="inputtos" #inputtos="ngModel" required [(ngModel)]="tos">
                    Ho preso visione dei
                    <a target="_blank" href="https://dvrsuite.it/termini-di-servizio">Termini di servizio</a>
                    <app-control-validation-result [control]="inputtos"></app-control-validation-result>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12">
                    <ngx-recaptcha2 [siteKey]="'6Le2WZoUAAAAAB-YMd1SLlNApOmtX7Um8gqYYTZY'" #captchaElem
                      [(ngModel)]="recaptchaToken" #inputRecaptchaToken="ngModel" name="recaptchaToken" [hl]="'it'"
                      required></ngx-recaptcha2>
                    <app-control-validation-result [control]="inputRecaptchaToken"></app-control-validation-result>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12">
                    <button type="submit" class="btn btn-primary btn-block btn-raised" [disabled]="!f.valid"
                      [ladda]="isSubmitting">Registrati</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="card-footer">
            <div class="float-left">
              <button class="btn btn-link" (click)="onForgotPassword()">Recupera Password</button>
            </div>
            <div class="float-right">
              <button class="btn btn-link" (click)="onLogin()">Accedi</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
