import { HttpClient } from '@angular/common/http';
import { HttpParamsExtension } from 'app/shared/extensions/httpParamsExtension';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LicenzaService = /** @class */ (function () {
    function LicenzaService(http) {
        this.http = http;
    }
    LicenzaService.prototype.getNuovo = function () {
        return this.http.get('/licenza/getnuovo');
    };
    LicenzaService.prototype.getCodiciLicenze = function (page, pageSize, filter) {
        if (pageSize === void 0) { pageSize = 10; }
        if (filter === void 0) { filter = ''; }
        var httpParams = HttpParamsExtension.ToHttpParams({
            page: page,
            pageSize: pageSize,
            filter: filter
        });
        return this.http
            .get('/licenza/GetCodiciLicenze', { params: httpParams });
    };
    ;
    LicenzaService.prototype.getTipiLicenze = function (page, pageSize, filter) {
        if (pageSize === void 0) { pageSize = 10; }
        if (filter === void 0) { filter = ''; }
        var httpParams = HttpParamsExtension.ToHttpParams({
            page: page,
            pageSize: pageSize,
            filter: filter
        });
        return this.http
            .get('/licenza/GetTipiLicenze', { params: httpParams });
    };
    ;
    LicenzaService.prototype.postCodiceLicenza = function (dto) {
        return this.http.post('/licenza/postCodiceLicenza', dto);
    };
    LicenzaService.prototype.attivaLicenza = function (idUtente, idCodiceLicenza) {
        var httpParams = HttpParamsExtension.ToHttpParams({
            idUtente: idUtente,
            idCodiceLicenza: idCodiceLicenza,
        });
        return this.http.post('/licenza/attivaLicenza', {}, { params: httpParams });
    };
    LicenzaService.ngInjectableDef = i0.defineInjectable({ factory: function LicenzaService_Factory() { return new LicenzaService(i0.inject(i1.HttpClient)); }, token: LicenzaService, providedIn: "root" });
    return LicenzaService;
}());
export { LicenzaService };
