//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ActivateLicenseStatus { 
	UtenteNonValido = 0, 
	LicenzaNonValida = 1, 
	LicenzaGiaAttiva = 2, 
	LicenzaAttivata = 3, 
	UtenteHaLicenzaAttiva = 4, 
	UtenteNonHaLicenzaAttiva = 5
}
