import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/shared/auth/auth.service';
import { ResetPasswordDto } from 'app/domain/ResetPasswordDto';
import { Router } from '@angular/router';
import { DtoValidationResult } from 'app/domain/DtoValidationResult';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.scss']
})
export class ResetPasswordPageComponent implements OnInit {
  isSubmitting = false;
  email = '';
  password = '';
  codice = '';
  validationResult: DtoValidationResult;

  constructor(private authService: AuthService,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit() {
  }

  onSubmit() {
    this.isSubmitting = true;

    const data = new ResetPasswordDto();
    data.Email = this.email;
    data.Password = this.password;
    data.ConfermaPassword = this.password;
    data.Codice = this.codice
    this.authService.resetPassword(data).subscribe(result => {
      this.isSubmitting = false;
      this.validationResult = result.ValidationResult;
      if (!result.HasErrors) {
        this.toastr.success('Password reimpostata');
        this.router.navigate(['/login']);
      }
    });
  }

  onLogin() {
    this.router.navigate(['/login']);
  }

  onRegister() {
    this.router.navigate(['/register']);
  }

}
