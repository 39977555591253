<nav class="header-navbar navbar navbar-expand-lg navbar-light bg-faded">
  <div class="container-fluid">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle d-lg-none float-left" data-toggle="collapse">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <span class="d-lg-none navbar-right navbar-collapse-toggle">
        <a class="open-navbar-container" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
          aria-controls="navbarSupportedContent">
          <i class="ft-more-vertical"></i>
        </a>
      </span>
      <span class="navbar-form navbar-right mt-1 d-none d-xl-inline">
        <!-- <img [src]="logoRivenditore" height="48" [title]="user.Licenza.NomeRivenditore"> -->
        <span>
          Licenza <a routerLink="/license">{{user.Licenza.NomeLicenza}}</a> distribuita da
          <!-- <strong>{{user.Licenza.NomeRivenditore}}</strong> -->
          <img [src]="logoRivenditore" height="48" [title]="user.Licenza.NomeRivenditore">
          <!-- Licenza <a routerLink="/license">{{user.Licenza.NomeLicenza}}</a> -->
          <!-- distribuita da
          <strong>{{user.Licenza.NomeRivenditore}}</strong> - Scade il
          <strong>{{user.Licenza.DataScadenza | date}}</strong> -->
        </span>
      </span>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="isCollapsed">
        <ul class="navbar-nav">
          <!-- <li class="nav-item mr-2 d-none d-lg-block">
                        <a href="javascript:;" class="nav-link" appToggleFullscreen (click)="ToggleClass()">                            
                            <i class="ft-maximize font-medium-3 blue-grey darken-4"></i>
                            <p class="d-none">fullscreen</p>
                        </a>
                    </li> -->
          <!-- <li class="nav-item" ngbDropdown [placement]="placement">
                        <a class="nav-link position-relative" id="dropdownBasic2" ngbDropdownToggle>
                            <i class="ft-bell font-medium-3 blue-grey darken-4"></i>
                            <span class="notification badge badge-pill badge-danger">4</span>
                            <p class="d-none">Notifications</p>
                        </a>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic2" class="notification-dropdown">
                            <a class="dropdown-item noti-container py-3 border-bottom border-bottom-blue-grey border-bottom-lighten-4">
                                <i class="ft-bell blue-grey lighten-3 float-left d-block font-large-1 mt-1 mr-2"></i>
                                <span class="noti-wrapper">
                                    <span class="noti-title line-height-1 d-block text-bold-400">New Order Received</span>
                                    <span class="noti-text">Lorem ipsum dolor sit ametitaque in, et!</span>
                                </span>
                            </a>
                            <a class="dropdown-item noti-container py-3 border-bottom border-bottom-blue-grey border-bottom-lighten-4">
                                <i class="ft-bell blue-grey lighten-3 float-left d-block font-large-1 mt-1 mr-2"></i>
                                <span class="noti-wrapper">
                                    <span class="noti-title line-height-1 d-block text-bold-400">New User Registered</span>
                                    <span class="noti-text">Lorem ipsum dolor sit ametitaque in </span>
                                </span>
                            </a>
                            <a class="dropdown-item noti-container py-3 border-bottom border-bottom-blue-grey border-bottom-lighten-4">
                                <i class="ft-bell blue-grey lighten-3 float-left d-block font-large-1 mt-1 mr-2"></i>
                                <span class="noti-wrapper">
                                    <span class="noti-title line-height-1 d-block text-bold-400">New Order Received</span>
                                    <span class="noti-text">Lorem ipsum dolor sit ametest?</span>
                                </span>
                            </a>
                            <a class="dropdown-item noti-container py-3">
                                <i class="ft-bell blue-grey lighten-3 float-left d-block font-large-1 mt-1 mr-2"></i>
                                <span class="noti-wrapper">
                                    <span class="noti-title line-height-1 d-block text-bold-400">New User Registered</span>
                                    <span class="noti-text">Lorem ipsum dolor sit ametnatus aut.</span>
                                </span>
                            </a>
                            <a class="noti-footer primary text-center d-block border-top border-top-blue-grey border-top-lighten-4 text-bold-400 py-1">Read All Notifications</a>
                        </div>
                    </li> -->
          <li class="nav-item" ngbDropdown [placement]="placement">
            <a class="nav-link position-relative" id="dropdownBasic3" ngbDropdownToggle>
              <i class="ft-user font-medium-3 blue-grey darken-4"></i> Benvenuto {{user.Nome}} {{user.Cognome}}
              <p class="d-none">User Settings</p>
            </a>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic3" class="text-left">
              <a class="dropdown-item py-1" routerLink="/settings">
                <i class="ft-settings mr-2"></i>
                <span>Impostazioni</span>
              </a>
              <a class="dropdown-item py-1" routerLink="/license">
                <i class="ft-lock mr-2"></i>
                <span>Licenza</span>
              </a>
              <!--
                            <a class="dropdown-item py-1" href="javascript:;">
                                <i class="ft-edit mr-2"></i>
                                <span>Edit Profile</span>
                            </a>
                            <a class="dropdown-item py-1" href="javascript:;">
                                <i class="ft-mail mr-2"></i>
                                <span>My Inbox</span>
                            </a>
                            <div class="dropdown-divider"></div> -->
              <a class="dropdown-item" (click)="logout()">
                <i class="ft-power mr-2"></i>
                <span>Esci</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
