import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-control-validation-result',
  // templateUrl: './control-validation-result.component.html',
  template: '<div *ngIf="errorMessage !== null"><div class="text-danger">{{errorMessage}}</div></div>',
  styleUrls: ['./control-validation-result.component.scss']
})
export class ControlValidationResultComponent implements OnInit {
  @Input() control: FormControl;

  constructor() { }

  ngOnInit() {
  }

  get errorMessage() {
    if (this.control === undefined || this.control.errors === undefined) {
      return null;
    }

    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName)) // && (this.control.touched) // || submitted)
      {
        const errMsg = this.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
        return errMsg;
      }
    }

    return null;
  }

  getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    // return this.translateService.instant('validator_' + validatorName);
    const config = {
      min: 'Min not  valid',
      max: 'Max not valid',
      required: 'Campo obbligatorio',
      minLengthArray: 'Campo obbligatorio',
      pattern: 'Campo non valido',
      invalidCreditCard: 'Campo non valido',
      invalidEmailAddress: 'Campo non valido',
      invalidPassword: 'Campo non valido',
      minlength: `Minimo ${validatorValue.requiredLength} caratteri`,
      maxlength: `Massimo ${validatorValue.requiredLength} caratteri`,
      email: 'Email non valida'
    };
    return config[validatorName];
  }
}
