
import { NgModule, LOCALE_ID, ErrorHandler, APP_INITIALIZER, InjectionToken, Inject, Injectable, Injector } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { NgbDateAdapter, NgbDateNativeUTCAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Angulartics2Module } from 'angulartics2';
import { NgxCaptchaModule } from 'ngx-captcha';

import { AppComponent } from './app.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { FullLayoutComponent } from './layouts/full/full-layout.component';

import { AuthService } from './shared/auth/auth.service';
import { AuthGuard } from './shared/auth/auth-guard.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthTokenInterceptor } from './shared/auth/auth-token-interceptor';
// import * as $ from 'jquery';


import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt, 'it-IT');

import { RefreshTokenService } from './shared/auth/refresh-token.service';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { SettingsPageComponent } from './pages/settings-page/settings-page.component';
import { LicensePageComponent } from './pages/license-page/license-page.component';
import { NgbDateITParserFormatter } from './NgbDateITParserFormatter';
import * as Rollbar from 'rollbar';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';




export function rollbarFactory() {
    const rollbarConfig: Rollbar.Configuration = {
        accessToken: '7ec6bbe555524c818d3833330622e65a',
        captureUncaught: true,
        captureUnhandledRejections: true,
        environment: 'production',
        payload: {
          environment: 'production',
          client: {
            javascript: {
              source_map_enabled: true, // true by default

              // -- Add this into your configuration ---
              code_version: '1.0',
              // ---------------------------------------

              // Optionally have Rollbar guess which frames the error was
              // thrown from when the browser does not provide line
              // and column numbers.
              guess_uncaught_frames: true
            }
          }
        }
    };

    return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector, @Inject(RollbarService) private rollbar: Rollbar) { }

    handleError(error): void {
        console.error('Exception handled - ', error);

        const production = environment.production;
        if (production) {
            this.rollbar.error(error.originalError || error);
            const router = this.injector.get(Router);
            const authService = this.injector.get(AuthService);
            authService.logout();
            router.navigate(['/error']);
        } else {
            alert('Errore inaspettato');
        }
    }
}



// export function init_app(refreshTokenService: RefreshTokenService) {
//     return () => refreshTokenService.keepTokenAlive();
// }

@NgModule({
    declarations: [
        AppComponent,
        FullLayoutComponent,
        ContentLayoutComponent,
        LoginPageComponent,
        RegisterPageComponent,
        ForgotPasswordPageComponent,
        ErrorPageComponent,
        ResetPasswordPageComponent,
        NotFoundPageComponent,
        SettingsPageComponent,
        LicensePageComponent
    ],
    imports: [
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        SharedModule,
        Angulartics2Module.forRoot(),
        NgxCaptchaModule
    ],
    providers: [
        AuthService,
        RefreshTokenService,
        { provide: RollbarService, useFactory: rollbarFactory },
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthTokenInterceptor,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useValue: 'it-IT'
        },
        // {
        //     provide: APP_INITIALIZER,
        //     useFactory: init_app,
        //     deps: [RefreshTokenService], multi: true
        // },
        {
            provide: NgbDateAdapter,
            // useClass: NgbDateNativeAdapter
            useClass: NgbDateNativeUTCAdapter
        },
        {
            provide: NgbDateParserFormatter,
            useClass: NgbDateITParserFormatter
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
