import { Component, HostBinding, OnInit } from '@angular/core';
import { NewsService } from 'app/services/news.service';

@Component({
    // moduleId: module.id,
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
    currentDate: Date = new Date();
    softwareVersion: string;

    constructor(private newsService: NewsService){
    }

    ngOnInit(): void {
       this.newsService.getSoftwareVersion().subscribe(x => this.softwareVersion = x);
    }
}
