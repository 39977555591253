/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "./navbar.component";
import * as i6 from "../../auth/auth.service";
import * as i7 from "../../../services/rivenditore.service";
var styles_NavbarComponent = [i0.styles];
var RenderType_NavbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavbarComponent, data: {} });
export { RenderType_NavbarComponent as RenderType_NavbarComponent };
export function View_NavbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 50, "nav", [["class", "header-navbar navbar navbar-expand-lg navbar-light bg-faded"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 49, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 17, "div", [["class", "navbar-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "button", [["class", "navbar-toggle d-lg-none float-left"], ["data-toggle", "collapse"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Toggle navigation"])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "icon-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "span", [["class", "icon-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [["class", "icon-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "span", [["class", "d-lg-none navbar-right navbar-collapse-toggle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [["aria-controls", "navbarSupportedContent"], ["class", "open-navbar-container"]], [[1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.isCollapsed = !_co.isCollapsed) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "ft-more-vertical"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 7, "span", [["class", "navbar-form navbar-right mt-1 d-none d-xl-inline"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Licenza "])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "a", [["routerLink", "/license"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(17, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" distribuita da "])), (_l()(), i1.ɵeld(19, 0, null, null, 0, "img", [["height", "48"]], [[8, "src", 4], [8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 30, "div", [["class", "navbar-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 29, "div", [["class", "collapse navbar-collapse"], ["id", "navbarSupportedContent"]], [[2, "collapse", null], [2, "show", null]], null, null, null, null)), i1.ɵdid(22, 16384, null, 0, i4.NgbCollapse, [], { collapsed: [0, "collapsed"] }, null), (_l()(), i1.ɵeld(23, 0, null, null, 27, "ul", [["class", "navbar-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 26, "li", [["class", "nav-item"], ["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(25, 212992, null, 2, i4.NgbDropdown, [i1.ChangeDetectorRef, i4.NgbDropdownConfig, i3.DOCUMENT, i1.NgZone], { placement: [0, "placement"] }, null), i1.ɵqud(335544320, 1, { _menu: 0 }), i1.ɵqud(335544320, 2, { _anchor: 0 }), (_l()(), i1.ɵeld(28, 0, null, null, 6, "a", [["aria-haspopup", "true"], ["class", "nav-link position-relative dropdown-toggle"], ["id", "dropdownBasic3"], ["ngbDropdownToggle", ""]], [[1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 29).toggleOpen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(29, 16384, null, 0, i4.ɵr, [i4.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[2, 4]], i4.ɵq, null, [i4.ɵr]), (_l()(), i1.ɵeld(31, 0, null, null, 0, "i", [["class", "ft-user font-medium-3 blue-grey darken-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(32, null, [" Benvenuto ", " ", " "])), (_l()(), i1.ɵeld(33, 0, null, null, 1, "p", [["class", "d-none"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["User Settings"])), (_l()(), i1.ɵeld(35, 0, null, null, 15, "div", [["aria-labelledby", "dropdownBasic3"], ["class", "text-left"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], null, null, null, null)), i1.ɵdid(36, 16384, [[1, 4]], 0, i4.ɵp, [i4.NgbDropdown, i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵeld(37, 0, null, null, 4, "a", [["class", "dropdown-item py-1"], ["routerLink", "/settings"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 38).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(38, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(39, 0, null, null, 0, "i", [["class", "ft-settings mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(40, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Impostazioni"])), (_l()(), i1.ɵeld(42, 0, null, null, 4, "a", [["class", "dropdown-item py-1"], ["routerLink", "/license"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 43).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(43, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(44, 0, null, null, 0, "i", [["class", "ft-lock mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(45, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Licenza"])), (_l()(), i1.ɵeld(47, 0, null, null, 3, "a", [["class", "dropdown-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(48, 0, null, null, 0, "i", [["class", "ft-power mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(49, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Esci"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = "/license"; _ck(_v, 16, 0, currVal_3); var currVal_9 = _co.isCollapsed; _ck(_v, 22, 0, currVal_9); var currVal_11 = _co.placement; _ck(_v, 25, 0, currVal_11); var currVal_20 = "/settings"; _ck(_v, 38, 0, currVal_20); var currVal_23 = "/license"; _ck(_v, 43, 0, currVal_23); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isCollapsed; _ck(_v, 10, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 16).target; var currVal_2 = i1.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_1, currVal_2); var currVal_4 = _co.user.Licenza.NomeLicenza; _ck(_v, 17, 0, currVal_4); var currVal_5 = _co.logoRivenditore; var currVal_6 = _co.user.Licenza.NomeRivenditore; _ck(_v, 19, 0, currVal_5, currVal_6); var currVal_7 = true; var currVal_8 = !i1.ɵnov(_v, 22).collapsed; _ck(_v, 21, 0, currVal_7, currVal_8); var currVal_10 = i1.ɵnov(_v, 25).isOpen(); _ck(_v, 24, 0, currVal_10); var currVal_12 = i1.ɵnov(_v, 29).dropdown.isOpen(); _ck(_v, 28, 0, currVal_12); var currVal_13 = _co.user.Nome; var currVal_14 = _co.user.Cognome; _ck(_v, 32, 0, currVal_13, currVal_14); var currVal_15 = true; var currVal_16 = i1.ɵnov(_v, 36).dropdown.isOpen(); var currVal_17 = i1.ɵnov(_v, 36).placement; _ck(_v, 35, 0, currVal_15, currVal_16, currVal_17); var currVal_18 = i1.ɵnov(_v, 38).target; var currVal_19 = i1.ɵnov(_v, 38).href; _ck(_v, 37, 0, currVal_18, currVal_19); var currVal_21 = i1.ɵnov(_v, 43).target; var currVal_22 = i1.ɵnov(_v, 43).href; _ck(_v, 42, 0, currVal_21, currVal_22); }); }
export function View_NavbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbar", [], null, null, null, View_NavbarComponent_0, RenderType_NavbarComponent)), i1.ɵdid(1, 8503296, null, 0, i5.NavbarComponent, [i6.AuthService, i7.RivenditoreService, i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavbarComponentNgFactory = i1.ɵccf("app-navbar", i5.NavbarComponent, View_NavbarComponent_Host_0, {}, {}, []);
export { NavbarComponentNgFactory as NavbarComponentNgFactory };
