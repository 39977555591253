<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          <i class="ft-lock"></i>
          Licenza</h4>
      </div>
      <div class="card-body">
        <div class="card-block">

          <div class="row">
            <div class="col-md-4">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">
                    <i class="ft-user"></i>
                    Informazioni licenza</h4>
                </div>
                <div class="card-body">
                  <div class="card-block">
                    <div>
                      <strong>Licenza</strong>
                    </div>
                    <div>
                      {{user.Licenza.NomeLicenza}} <span *ngIf="user.Licenza.IsNfr"
                        class="badge badge-primary">NFR</span>
                    </div>
                    <div>
                      <strong>Distribuita da</strong>
                    </div>
                    <div>
                      {{user.Licenza.NomeRivenditore}}
                      <img [src]="logoRivenditore" height="48" [title]="user.Licenza.NomeRivenditore">
                    </div>
                    <div>
                      <strong>Data attivazione</strong>
                    </div>
                    <div>
                      {{user.Licenza.DataAttivazione | date}}
                    </div>
                    <div>
                      <strong>Data scadenza</strong>
                    </div>
                    <div [ngClass]="{'text-danger': user.Licenza.IsLicenzaScaduta}">
                      {{user.Licenza.DataScadenza | date}}
                    </div>
                    <div>
                      <strong>Numero DVR</strong>
                    </div>
                    <div>
                      {{user.Licenza.NumeroDvr}}
                    </div>
                    <div>
                      <strong>Spazio archivio</strong>
                    </div>
                    <div>
                      {{user.Licenza.SpazioArchivio}} MB
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">
                    <i class="ft-shield"></i>
                    Attiva o rinnova la tua licenza utilizzando il codice licenza</h4>
                </div>
                <div class="card-body">
                  <div class="card-block">
                    <form novalidate class="form" #fAttivazione="ngForm">
                      <div class="form-body">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Codice licenza</label>
                              <input type="text" class="form-control" name="codiceLicenza" [(ngModel)]="codiceLicenza"
                                required #inputCodiceLicenza="ngModel">
                              <app-control-validation-result [control]="inputCodiceLicenza">
                              </app-control-validation-result>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button (click)="attivaCodiceLicenza(fAttivazione)" [ladda]="isSubmitting" type="button"
                        class="btn btn-raised btn-success" [disabled]="!fAttivazione.valid"
                        title="{{ inputCodiceLicenza.valid ? '' : 'Correggere gli errori'}}">
                        <i class="fa fa-floppy-o"></i> Attiva \ rinnova
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>