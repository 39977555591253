import { RouteInfo } from './sidebar.metadata';
import { ApplicationRoles } from 'app/domain/ApplicationRoles';

export const ROUTES: RouteInfo[] = [
    {
        path: '/dashboard',
        title: 'Home',
        icon: 'fa fa-tachometer',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: []
    },
    {
        path: '/dvr',
        title: 'DVR',
        icon: 'fa fa-book',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: []
    },
    {
        path: '/news',
        title: 'Notizie',
        icon: 'fa fa-newspaper-o',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: []
    },
    {
        path: '/informative',
        title: 'Informative',
        icon: 'fa fa-address-card-o',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: []
    },
    {
        path: '/dichiarazioni',
        title: 'Dichiarazioni',
        icon: 'fa fa-address-card',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: []
    },
    {
        path: '/utenti',
        title: 'Utenti',
        icon: 'fa fa-users',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [ApplicationRoles.RuoloAdmin, ApplicationRoles.RuoloPowerUser, ApplicationRoles.RuoloRivenditore]
    },
    {
        path: '/licenze',
        title: 'Licenze',
        icon: 'fa fa-certificate',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [ApplicationRoles.RuoloAdmin, ApplicationRoles.RuoloPowerUser, ApplicationRoles.RuoloRivenditore]
    },
    {
        path: '/tipilicenze',
        title: 'Tipi licenze',
        icon: 'fa fa-list-ol',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [ApplicationRoles.RuoloAdmin, ApplicationRoles.RuoloPowerUser, ApplicationRoles.RuoloRivenditore]
    },
    {
        path: '/rivenditori',
        title: 'Rivenditori',
        icon: 'fa fa-briefcase',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: [ApplicationRoles.RuoloAdmin]
    }
];
