import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from './layouts/full/full-layout.component';

import { AuthGuard } from './shared/auth/auth-guard.service';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { SettingsPageComponent } from './pages/settings-page/settings-page.component';
import { LicensePageComponent } from './pages/license-page/license-page.component';


const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: './pages/home/home.module#HomeModule'
      },
      {
        path: 'dvr',
        loadChildren: './pages/dvr/dvr.module#DvrModule'
      },
      {
        path: 'rivenditori',
        loadChildren: './pages/rivenditori/rivenditori.module#RivenditoriModule'
      },
      {
        path: 'utenti',
        loadChildren: './pages/utenti/utenti.module#UtentiModule'
      },
      {
        path: 'news',
        loadChildren: './pages/news/news.module#NewsModule'
      },
      {
        path: 'informative',
        loadChildren: './pages/informative/informative.module#InformativeModule'
      },
      {
        path: 'dichiarazioni',
        loadChildren: './pages/dichiarazioni/dichiarazioni.module#DichiarazioniModule'
      },
      {
        path: 'licenze',
        loadChildren: './pages/licenze/licenze.module#LicenzeModule'
      },
      {
        path: 'tipilicenze',
        loadChildren: './pages/tipi-licenze/tipi-licenze.module#TipiLicenzeModule'
      },
      {
        path: 'settings',
        component: SettingsPageComponent
      },
      {
        path: 'license',
        component: LicensePageComponent
      }
    ], canActivate: [AuthGuard], canActivateChild: [AuthGuard]
  },
  { path: 'login', component: LoginPageComponent },
  { path: 'register', component: RegisterPageComponent },
  { path: 'forgotpassword', component: ForgotPasswordPageComponent },
  { path: 'resetpassword', component: ResetPasswordPageComponent },
  { path: 'error', component: ErrorPageComponent },
  { path: '**', component: NotFoundPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
