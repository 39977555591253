import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NewsInfoDto } from 'app/domain/NewsInfoDto';
import { PagedList } from 'app/domain/PagedList';
import { HttpParamsExtension } from 'app/shared/extensions/httpParamsExtension';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private http: HttpClient) { }

  get(page = 0, pageSize = 10, filter = ''): Observable<PagedList<NewsInfoDto>> {
    const httpParams = HttpParamsExtension.ToHttpParams({
      page: page,
      pageSize: pageSize,
      filter: filter
    });

    return this.http.get<PagedList<NewsInfoDto>>('/news/get', { params: httpParams });
  }

  getSoftwareVersion() {
    return this.http.get<string>('/news/getsoftwareversion');
  }
}
