<section>
  <div class="container-fluid">
    <div class="row full-height-vh">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="card  text-center width-400">
          <div class="card-img overlap">
            <img class="mb-1" src="assets/img/logos/logo-color-big.png" width="350">
          </div>
          <div class="card-body">
            <div class="card-block">
              <h2>DVR Suite</h2>
              <h5>Lo strumento perfetto per compilare i DVR!</h5>
              <form novalidate #f="ngForm">
                <app-validation-result [validationResult]="validationResult"></app-validation-result>
                <div class="form-group">
                  <div class="col-md-12">
                    <input type="email" class="form-control" name="inputEmail" #inputEmail="ngModel" placeholder="Email"
                      [(ngModel)]="email" required email>
                    <app-control-validation-result [control]="inputEmail"></app-control-validation-result>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12">
                    <input type="text" class="form-control" name="inputCodice" #inputCodice="ngModel"
                      placeholder="Codice" [(ngModel)]="codice" required>
                    <app-control-validation-result [control]="inputCodice"></app-control-validation-result>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-md-12">
                    <input type="password" class="form-control" name="inputPass" #inputPass="ngModel"
                      placeholder="Password" [(ngModel)]="password" required minlength="8">
                    <app-control-validation-result [control]="inputPass"></app-control-validation-result>
                  </div>
                </div>
                <!-- <div class="form-group">
                                    <div class="col-md-12">
                                        <jhi-re-captcha site_key="6LcayysUAAAAAOkk5VzyYbW89rVl753BQ6UFfK4B" (captchaResponse)="handleCorrectCaptcha($event)"></jhi-re-captcha>
                                    </div>
                                </div> -->
                <!-- <div class="form-group">
                  <div class="row">
                    <div class="col-md-12">
                      Procedendo accetti i termini di servizio
                    </div>
                  </div>
                </div> -->
                <!-- <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <a [routerLink]="['/privacypolicy']">Privacy Policy</a>
                                        </div>
                                    </div>
                                </div> -->
                <div class="form-group">
                  <div class="col-md-12">
                    <button type="button" class="btn btn-primary btn-block btn-raised" [disabled]="!f.valid"
                      [ladda]="isSubmitting" (click)="onSubmit()">
                      Reset
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="card-footer">
            <div class="float-left">
              <button class="btn btn-link" (click)="onLogin()">Accedi</button>
            </div>
            <div class="float-right">
              <button class="btn btn-link" (click)="onRegister()">Registrati</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
