//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export class ResetPasswordDto
{
	public Email: string;
	public Password: string;
	public ConfermaPassword: string;
	public Codice: string;
	public AutoGenerateCode: boolean;
}
