<div class="alert alert-warning m-t-sm" *ngIf="form.invalid">
    <!-- <ul>
      <li *ngFor="let errorResult of validationResult.ErrorResults">
        <span *ngFor="let errormessage of errorResult.ErrorMessages">
          {{errormessage}}
        </span>
      </li>
    </ul> -->
    <i class="fa fa-exclamation-triangle"></i>
    Correggere gli errori
  </div>
