import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LaddaModule } from 'angular2-ladda';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ToggleFullscreenDirective } from './directives/toggle-fullscreen.directive';
import { FormsModule } from '@angular/forms';
import { ValidationResultComponent } from './components/validation-result/validation-result.component';
import { ToastrModule } from 'ngx-toastr';
import { FormValidationResultComponent } from './components/form-validation-result/form-validation-result.component';
import { ControlValidationResultComponent } from './components/control-validation-result/control-validation-result.component';
import { FileUploadModule } from 'ng2-file-upload';
import { ChartsModule } from 'ng2-charts';
import { GroupByPipe } from './pipes/group-by.pipe';
import { StatoDocumentoEditorComponent } from './components/stato-documento-editor/stato-documento-editor.component';
import { ChecklistDirective } from './directives/checklist-directive';
import { DistributedByComponent } from './components/distributed-by/distributed-by.component';
import { CheckRoleDirective } from './directives/checkrole-directive';
import { CategoriaLicenzaEditorComponent } from './components/categoria-licenza-editor/categoria-licenza-editor.component';
import { OptionsModalComponent } from './components/options-modal/options-modal.component';
import { ExpanderComponent } from './components/expander/expander.component';
import { PromemoriaEditorComponent } from './components/promemoria-editor/promemoria-editor.component';
import { DownloadDichiarazioniComponent } from './components/download-dichiarazioni/download-dichiarazioni.component';
import { PermissionEditorComponent } from './components/permission-editor/permission-editor.component';
import { HelperComponent } from './components/helper/helper.component';

@NgModule({
    exports: [
        CommonModule,
        FormsModule,
        NgbModule,
        LaddaModule,
        FileUploadModule,
        ChartsModule,
        GroupByPipe,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ValidationResultComponent,
        FormValidationResultComponent,
        DistributedByComponent,
        ControlValidationResultComponent,
        StatoDocumentoEditorComponent,
        CategoriaLicenzaEditorComponent,
        ToggleFullscreenDirective,
        ChecklistDirective,
        CheckRoleDirective,
        OptionsModalComponent,
        ExpanderComponent,
        PromemoriaEditorComponent,
        DownloadDichiarazioniComponent,
        PermissionEditorComponent,
        HelperComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        NgbModule.forRoot(),
        ToastrModule.forRoot(),
        LaddaModule,
        FileUploadModule,
        ChartsModule,
    ],
    declarations: [
        GroupByPipe,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ValidationResultComponent,
        FormValidationResultComponent,
        ControlValidationResultComponent,
        StatoDocumentoEditorComponent,
        DistributedByComponent,
        ToggleFullscreenDirective,
        ChecklistDirective,
        CheckRoleDirective,
        CategoriaLicenzaEditorComponent,
        OptionsModalComponent,
        ExpanderComponent,
        PromemoriaEditorComponent,
        DownloadDichiarazioniComponent,
        PermissionEditorComponent,
        HelperComponent
    ]
})
export class SharedModule { }
