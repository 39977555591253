<!--Footer Starts-->
<footer>
  <div class="container-fluid">
    <p class="copyright text-center">
      &copy; {{currentDate | date: 'yyyy'}} <a>DVR Suite - {{softwareVersion}}</a>
      <!--&copy;  <a>PIXINVENT</a>, made with love for a better web-->
    </p>
  </div>
</footer>
<!--Footer Ends-->
