import { Component, AfterViewChecked, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { UtenteDto } from 'app/domain/UtenteDto';
import { Router } from '@angular/router';
import { RivenditoreService } from 'app/services/rivenditore.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit, AfterViewChecked {
    toggleClass = 'ft-maximize';
    placement = 'bottom-right'
    public isCollapsed = true;
    user: UtenteDto;
    logoRivenditore: string;

    constructor(private authService: AuthService,
        private rivenditoreService: RivenditoreService,
        private router: Router) { }

    ngOnInit(): void {
        this.user = this.authService.getUser();
        this.logoRivenditore = this.rivenditoreService.getLogoUrl(this.user.Licenza.IdRivenditore);
    }

    ngAfterViewChecked() {

        // setTimeout(() => {
        //     const wrapperDiv = document.getElementsByClassName('wrapper')[0];
        //     const dir = wrapperDiv.getAttribute('dir');
        //     if (dir === 'rtl') {
        //         this.placement = 'bottom-left';
        //     } else if (dir === 'ltr') {
        //         this.placement = 'bottom-right';
        //     }
        // }, 3000);


    }

    ToggleClass() {
        if (this.toggleClass === 'ft-maximize') {
            this.toggleClass = 'ft-minimize';
        } else {
            this.toggleClass = 'ft-maximize'
        }
    }

    logout() {
        this.authService.logout();
        this.router.navigate(['login']);
    }
}
