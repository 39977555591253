/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/router";
import * as i3 from "../../directives/checkrole-directive";
import * as i4 from "../../auth/auth.service";
import * as i5 from "./sidebar.component";
var styles_SidebarComponent = [];
var RenderType_SidebarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SidebarComponent, data: {} });
export { RenderType_SidebarComponent as RenderType_SidebarComponent };
function View_SidebarComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpad(2, 1), (_l()(), i0.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, _v.parent.parent.parent.context.$implicit.badgeClass); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.parent.parent.context.$implicit.badge; _ck(_v, 3, 0, currVal_1); }); }
function View_SidebarComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(2, 1), (_l()(), i0.ɵeld(3, 0, null, null, 2, "i", [], null, null, null, null, null)), i0.ɵdid(4, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpad(5, 1), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "menu-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_4)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = ((_v.parent.parent.context.$implicit.class === "") ? _ck(_v, 2, 0, _v.parent.parent.context.$implicit.path) : null); _ck(_v, 1, 0, currVal_2); var currVal_3 = _ck(_v, 5, 0, _v.parent.parent.context.$implicit.icon); _ck(_v, 4, 0, currVal_3); var currVal_5 = (_v.parent.parent.context.$implicit.badge != ""); _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = _v.parent.parent.context.$implicit.title; _ck(_v, 7, 0, currVal_4); }); }
function View_SidebarComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpad(2, 1), (_l()(), i0.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, _v.parent.parent.parent.context.$implicit.badgeClass); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.parent.parent.context.$implicit.badge; _ck(_v, 3, 0, currVal_1); }); }
function View_SidebarComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), i0.ɵpad(1, 1), (_l()(), i0.ɵeld(2, 0, null, null, 2, "i", [], null, null, null, null, null)), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpad(4, 1), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "menu-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_6)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = _ck(_v, 4, 0, _v.parent.parent.context.$implicit.icon); _ck(_v, 3, 0, currVal_1); var currVal_3 = (_v.parent.parent.context.$implicit.badge != ""); _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _ck(_v, 1, 0, _v.parent.parent.context.$implicit.path); _ck(_v, 0, 0, currVal_0); var currVal_2 = _v.parent.parent.context.$implicit.title; _ck(_v, 6, 0, currVal_2); }); }
function View_SidebarComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpad(2, 1), (_l()(), i0.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, _v.parent.parent.context.$implicit.badgeClass); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.parent.context.$implicit.badge; _ck(_v, 3, 0, currVal_1); }); }
function View_SidebarComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, [[4, 4], [2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(2, 1), (_l()(), i0.ɵeld(3, 0, null, null, 2, "i", [], null, null, null, null, null)), i0.ɵdid(4, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpad(5, 1), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "menu-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_10)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = ((_v.parent.context.$implicit.submenu.length > 0) ? null : _ck(_v, 2, 0, _v.parent.context.$implicit.path)); _ck(_v, 1, 0, currVal_2); var currVal_3 = _ck(_v, 5, 0, _v.parent.context.$implicit.icon); _ck(_v, 4, 0, currVal_3); var currVal_5 = (_v.parent.context.$implicit.badge != ""); _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = _v.parent.context.$implicit.title; _ck(_v, 7, 0, currVal_4); }); }
function View_SidebarComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpad(2, 1), (_l()(), i0.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, _v.parent.parent.context.$implicit.badgeClass); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.parent.context.$implicit.badge; _ck(_v, 3, 0, currVal_1); }); }
function View_SidebarComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "a", [], [[8, "href", 4]], null, null, null, null)), i0.ɵpad(1, 1), (_l()(), i0.ɵeld(2, 0, null, null, 2, "i", [], null, null, null, null, null)), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpad(4, 1), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "menu-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_12)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = _ck(_v, 4, 0, _v.parent.context.$implicit.icon); _ck(_v, 3, 0, currVal_1); var currVal_3 = (_v.parent.context.$implicit.badge != ""); _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _ck(_v, 1, 0, _v.parent.context.$implicit.path); _ck(_v, 0, 0, currVal_0); var currVal_2 = _v.parent.context.$implicit.title; _ck(_v, 6, 0, currVal_2); }); }
function View_SidebarComponent_16(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpad(2, 1), (_l()(), i0.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, _v.parent.parent.context.$implicit.badgeClass); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.parent.context.$implicit.badge; _ck(_v, 3, 0, currVal_1); }); }
function View_SidebarComponent_15(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, [[6, 4], [4, 4], [2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(2, 1), (_l()(), i0.ɵeld(3, 0, null, null, 2, "i", [], null, null, null, null, null)), i0.ɵdid(4, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpad(5, 1), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "menu-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_16)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = _ck(_v, 2, 0, _v.parent.context.$implicit.path); _ck(_v, 1, 0, currVal_2); var currVal_3 = _ck(_v, 5, 0, _v.parent.context.$implicit.icon); _ck(_v, 4, 0, currVal_3); var currVal_5 = (_v.parent.context.$implicit.badge != ""); _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = _v.parent.context.$implicit.title; _ck(_v, 7, 0, currVal_4); }); }
function View_SidebarComponent_18(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpad(2, 1), (_l()(), i0.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, _v.parent.parent.context.$implicit.badgeClass); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.parent.context.$implicit.badge; _ck(_v, 3, 0, currVal_1); }); }
function View_SidebarComponent_17(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "a", [], [[8, "href", 4]], null, null, null, null)), i0.ɵpad(1, 1), (_l()(), i0.ɵeld(2, 0, null, null, 2, "i", [], null, null, null, null, null)), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpad(4, 1), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "menu-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_18)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = _ck(_v, 4, 0, _v.parent.context.$implicit.icon); _ck(_v, 3, 0, currVal_1); var currVal_3 = (_v.parent.context.$implicit.badge != ""); _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _ck(_v, 1, 0, _v.parent.context.$implicit.path); _ck(_v, 0, 0, currVal_0); var currVal_2 = _v.parent.context.$implicit.title; _ck(_v, 6, 0, currVal_2); }); }
function View_SidebarComponent_14(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "li", [["routerLinkActive", "active"]], null, null, null, null, null)), i0.ɵdid(1, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i0.ɵqud(603979776, 5, { links: 1 }), i0.ɵqud(603979776, 6, { linksWithHrefs: 1 }), i0.ɵpod(4, { exact: 0 }), i0.ɵdid(5, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpad(6, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_15)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["externalSubSubLinkBlock", 2]], null, 0, null, View_SidebarComponent_17))], function (_ck, _v) { var currVal_0 = _ck(_v, 4, 0, true); var currVal_1 = "active"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 6, 0, _v.context.$implicit.class); _ck(_v, 5, 0, currVal_2); var currVal_3 = !_v.context.$implicit.isExternalLink; var currVal_4 = i0.ɵnov(_v, 9); _ck(_v, 8, 0, currVal_3, currVal_4); }, null); }
function View_SidebarComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [["class", "menu-content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_14)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.submenu; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SidebarComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "li", [], null, null, null, null, null)), i0.ɵdid(1, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 3, { links: 1 }), i0.ɵqud(603979776, 4, { linksWithHrefs: 1 }), i0.ɵdid(4, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpad(5, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_9)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["externalSubLinkBlock", 2]], null, 0, null, View_SidebarComponent_11)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_13)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit.submenu.length > 0) ? "" : "active"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _ck(_v, 5, 0, _v.context.$implicit.class); _ck(_v, 4, 0, currVal_1); var currVal_2 = !_v.context.$implicit.isExternalLink; var currVal_3 = i0.ɵnov(_v, 8); _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = (_v.context.$implicit.submenu.length > 0); _ck(_v, 10, 0, currVal_4); }, null); }
function View_SidebarComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [["class", "menu-content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_8)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.submenu; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SidebarComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "li", [], null, null, null, null, null)), i0.ɵdid(1, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 1, { links: 1 }), i0.ɵqud(603979776, 2, { linksWithHrefs: 1 }), i0.ɵdid(4, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpad(5, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_3)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["externalLinkBlock", 2]], null, 0, null, View_SidebarComponent_5)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_7)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit.submenu.length != 0) ? "" : "active"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _ck(_v, 5, 0, _v.parent.context.$implicit.class); _ck(_v, 4, 0, currVal_1); var currVal_2 = !_v.parent.context.$implicit.isExternalLink; var currVal_3 = i0.ɵnov(_v, 8); _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = (_v.parent.context.$implicit.submenu.length > 0); _ck(_v, 10, 0, currVal_4); }, null); }
function View_SidebarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_2)), i0.ɵdid(2, 16384, null, 0, i3.CheckRoleDirective, [i4.AuthService, i0.TemplateRef, i0.ViewContainerRef], { appCheckRole: [0, "appCheckRole"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.roles; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SidebarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "sidebar-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "logo clearfix"], ["style", "background-color: white;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "a", [["class", "logo-text float-left"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(4, 1), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "logo-img"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "img", [["src", "assets/img/logo.png"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "sidebar-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 3, "div", [["class", "nav-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "ul", [["class", "navigation"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_1)), i0.ɵdid(11, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 4, 0, "/"); _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.menuItems; _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3).target; var currVal_1 = i0.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_SidebarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebar", [], null, null, null, View_SidebarComponent_0, RenderType_SidebarComponent)), i0.ɵdid(1, 114688, null, 0, i5.SidebarComponent, [i2.Router, i2.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarComponentNgFactory = i0.ɵccf("app-sidebar", i5.SidebarComponent, View_SidebarComponent_Host_0, {}, {}, []);
export { SidebarComponentNgFactory as SidebarComponentNgFactory };
