import { Component, OnInit } from '@angular/core';
import { DtoValidationResult } from 'app/domain/DtoValidationResult';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/shared/auth/auth.service';
import { RegistraUtenteDto } from 'app/domain/RegistraUtenteDto';
import { ToastrService } from 'ngx-toastr';
import { IdRivenditori } from 'app/domain/IdRivenditori';

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss']
})
export class RegisterPageComponent implements OnInit {
  validationResult: DtoValidationResult;
  isSubmitting: boolean;

  idRivenditore: string;
  nome: string;
  cognome: string;
  azienda: string;
  ruolo: string;
  indirizzo: string;
  cap: string;
  citta: string;
  provincia: string;
  email: string;
  telefono: string;
  password: string;
  privacy: boolean;
  tos: boolean;
  recaptchaToken = '';

  constructor(private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.idRivenditore = this.route.snapshot.queryParams['ref'];
    if (!this.idRivenditore) {
      this.idRivenditore = IdRivenditori.DvrTeamId;
    }
  }

  onSubmit() {
    this.isSubmitting = true;
    const data = new RegistraUtenteDto();
    data.Nome = this.nome;
    data.Cognome = this.cognome;
    data.Azienda = this.azienda;
    data.Ruolo = this.ruolo;
    data.Indirizzo = this.indirizzo;
    data.Cap = this.cap;
    data.Citta = this.citta;
    data.Provincia = this.provincia;
    data.Email = this.email;
    data.ConfermaEmail = this.email;
    data.Password = this.password;
    data.ConfermaPassword = this.password;
    data.Culture = 'it-IT';
    data.TimeZone = 'W. Europe Standard Time';
    data.Paese = 'Italia';
    data.IdRivenditore = this.idRivenditore;
    data.Telefono = this.telefono;

    this.authService.registra(data).subscribe(result => {
      this.isSubmitting = false;

      if (result.HasErrors) {
        this.validationResult = result.ValidationResult;
      } else {
        this.toastr.success('Registrazione effettuata');
        this.router.navigate(['/login']);
      }
    });
  }

  onLogin() {
    this.router.navigate(['/login']);
  }

  onForgotPassword() {
    this.router.navigate(['/forgotpassword']);
  }
}
