import { HttpClient } from '@angular/common/http';
import { HttpParamsExtension } from 'app/shared/extensions/httpParamsExtension';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var NewsService = /** @class */ (function () {
    function NewsService(http) {
        this.http = http;
    }
    NewsService.prototype.get = function (page, pageSize, filter) {
        if (page === void 0) { page = 0; }
        if (pageSize === void 0) { pageSize = 10; }
        if (filter === void 0) { filter = ''; }
        var httpParams = HttpParamsExtension.ToHttpParams({
            page: page,
            pageSize: pageSize,
            filter: filter
        });
        return this.http.get('/news/get', { params: httpParams });
    };
    NewsService.prototype.getSoftwareVersion = function () {
        return this.http.get('/news/getsoftwareversion');
    };
    NewsService.ngInjectableDef = i0.defineInjectable({ factory: function NewsService_Factory() { return new NewsService(i0.inject(i1.HttpClient)); }, token: NewsService, providedIn: "root" });
    return NewsService;
}());
export { NewsService };
