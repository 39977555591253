import { OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
var ControlValidationResultComponent = /** @class */ (function () {
    function ControlValidationResultComponent() {
    }
    ControlValidationResultComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(ControlValidationResultComponent.prototype, "errorMessage", {
        get: function () {
            if (this.control === undefined || this.control.errors === undefined) {
                return null;
            }
            for (var propertyName in this.control.errors) {
                if (this.control.errors.hasOwnProperty(propertyName)) // && (this.control.touched) // || submitted)
                 {
                    var errMsg = this.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
                    return errMsg;
                }
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    ControlValidationResultComponent.prototype.getValidatorErrorMessage = function (validatorName, validatorValue) {
        // return this.translateService.instant('validator_' + validatorName);
        var config = {
            min: 'Min not  valid',
            max: 'Max not valid',
            required: 'Campo obbligatorio',
            minLengthArray: 'Campo obbligatorio',
            pattern: 'Campo non valido',
            invalidCreditCard: 'Campo non valido',
            invalidEmailAddress: 'Campo non valido',
            invalidPassword: 'Campo non valido',
            minlength: "Minimo " + validatorValue.requiredLength + " caratteri",
            maxlength: "Massimo " + validatorValue.requiredLength + " caratteri",
            email: 'Email non valida'
        };
        return config[validatorName];
    };
    return ControlValidationResultComponent;
}());
export { ControlValidationResultComponent };
