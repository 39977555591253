import { Component, Input, OnInit } from '@angular/core';
import { RivenditoreService } from 'app/services/rivenditore.service';

@Component({
  selector: 'app-distributed-by',
  templateUrl: './distributed-by.component.html',
  styleUrls: ['./distributed-by.component.scss']
})
export class DistributedByComponent implements OnInit {
  logoRivenditore = '';

  @Input()
  idRivenditore = '';

  constructor(private rivenditoreService: RivenditoreService) { }

  ngOnInit() {
    if (this.idRivenditore) {
      this.logoRivenditore = this.rivenditoreService.getLogoUrl(this.idRivenditore);
    }
  }
}
