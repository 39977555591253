<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          <i class="ft-settings"></i>
          Impostazioni</h4>
      </div>
      <div class="card-body">
        <div class="card-block">

          <div class="row">
            <div class="col-md-6">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">
                    <i class="ft-user"></i>
                    Profilo</h4>
                </div>
                <div class="card-body">
                  <div class="card-block">
                    <form novalidate class="form" #f="ngForm">
                      <app-validation-result [validationResult]="validationResult"></app-validation-result>
                      <app-form-validation-result [form]="f"></app-form-validation-result>
                      <div class="form-body">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Nome</label>
                              <input type="text" class="form-control" name="nome" [(ngModel)]="user.Nome" required
                                #inputNome="ngModel">
                              <app-control-validation-result [control]="inputNome"></app-control-validation-result>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Cognome</label>
                              <input type="text" class="form-control" name="cognome" [(ngModel)]="user.Cognome" required
                                #inputCognome="ngModel">
                              <app-control-validation-result [control]="inputCognome"></app-control-validation-result>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Azienda</label>
                              <input type="text" class="form-control" name="azienda" [(ngModel)]="user.Azienda" required
                                #inputAzienda="ngModel">
                              <app-control-validation-result [control]="inputAzienda"></app-control-validation-result>
                            </div>
                          </div>
                          <!-- <div class="col-md-6">
                            <div class="form-group">
                              <label>Ruolo</label>
                              <input type="text" class="form-control" name="ruolo" [(ngModel)]="user.Ruolo" required
                                #inputRuolo="ngModel">
                              <app-control-validation-result [control]="inputRuolo"></app-control-validation-result>
                            </div>
                          </div> -->
                          <!-- </div> -->
                          <!-- <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Telefono</label>
                              <input type="text" class="form-control" name="telefono" [(ngModel)]="user.Telefono"
                                required #inputTelefono="ngModel">
                              <app-control-validation-result [control]="inputTelefono"></app-control-validation-result>
                            </div>
                          </div> -->
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Email</label>
                              <input type="text" class="form-control" name="email" [ngModel]="user.Email" readonly>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Indirizzo</label>
                              <input type="text" class="form-control" name="indirizzo" [(ngModel)]="user.Indirizzo"
                                required #inputIndirizzo="ngModel">
                              <app-control-validation-result [control]="inputIndirizzo"></app-control-validation-result>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Cap</label>
                              <input type="text" class="form-control" name="cap" [(ngModel)]="user.Cap" required
                                #inputCap="ngModel">
                              <app-control-validation-result [control]="inputCap"></app-control-validation-result>
                            </div>
                          </div>
                        </div> -->
                        <!-- <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Città</label>
                              <input type="text" class="form-control" name="citta" [(ngModel)]="user.Citta" required
                                #inputCitta="ngModel">
                              <app-control-validation-result [control]="inputCitta"></app-control-validation-result>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Provincia</label>
                              <input type="text" class="form-control" name="provincia" [(ngModel)]="user.Provincia"
                                required #inputProvincia="ngModel">
                              <app-control-validation-result [control]="inputProvincia"></app-control-validation-result>
                            </div>
                          </div>
                        </div> -->
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Logo aziendale (PNG o JPEG)</label>
                              <input type="file" ng2FileSelect [uploader]="uploader" *ngIf="!user.HasLogo"
                                class="form-control">
                              <br>
                              <img class="img-responsive" [src]="getLogoUrl()" *ngIf="user.HasLogo" /> <br>
                              <button *ngIf="user.HasLogo" (click)="deleteLogo()" class="btn btn-danger btn-raised">
                                <i class="fa fa-minus-circle"></i>Rimuovi logo</button>
                            </div>
                          </div>

                        </div>
                      </div>
                      <button (click)="saveProfile(f)" [ladda]="isSubmitting" type="button"
                        class="btn btn-raised btn-success" [disabled]="!f.valid"
                        title="{{ f.valid ? '' : 'Correggere gli errori'}}">
                        <i class="fa fa-floppy-o"></i> Salva
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">
                    <i class="ft-shield"></i>
                    Sicurezza</h4>
                </div>
                <div class="card-body">
                  <div class="card-block">
                    <form novalidate class="form" #fpassword="ngForm">
                      <app-validation-result [validationResult]="validationResultPassword"></app-validation-result>
                      <!-- <app-form-validation-result [form]="fpassword"></app-form-validation-result> -->
                      <div class="form-body">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Vecchia password</label>
                              <input type="password" class="form-control" name="vecchiapassword"
                                [(ngModel)]="vecchiaPassword" required #inputVecchiaPassword="ngModel">
                              <app-control-validation-result [control]="inputVecchiaPassword">
                              </app-control-validation-result>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Nuova password</label>
                              <input type="password" class="form-control" name="nuovapassword"
                                [(ngModel)]="nuovaPassword" required #inputNuovaPassword="ngModel">
                              <app-control-validation-result [control]="inputNuovaPassword">
                              </app-control-validation-result>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button (click)="changePassword(fpassword)" [ladda]="isSubmitting" type="button"
                        class="btn btn-raised btn-success" [disabled]="!fpassword.valid"
                        title="{{ fpassword.valid ? '' : 'Correggere gli errori'}}">
                        <i class="fa fa-floppy-o"></i> Modifica password
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
