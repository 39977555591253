<section>
  <div class="container-fluid">
    <div class="row full-height-vh">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="card  text-center width-400">
          <!-- <div class="card-img overlap">
                        <img alt="element 06" class="mb-1" src="assets/img/logos/logo.png" width="190">
                    </div> -->
          <div class="card-body">
            <div class="card-block">
              <h2>DVR Suite</h2>
              <h5>Pagina non trovata!</h5>
            </div>
          </div>
          <div class="card-footer">
            <a routerLink="/">Home</a>
            <!-- <div class="float-right">
                            <a (click)="onRegister()">Registrati</a>
                        </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
