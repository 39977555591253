import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/shared/auth/auth.service';
import { RivenditoreService } from 'app/services/rivenditore.service';
import { UtenteDto } from 'app/domain/UtenteDto';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LicenzaService } from 'app/services/licenza.service';
import { ActivateLicenseStatus } from 'app/domain/ActivateLicenseStatus';

@Component({
  selector: 'app-license-page',
  templateUrl: './license-page.component.html',
  styleUrls: ['./license-page.component.scss']
})
export class LicensePageComponent implements OnInit {
  user: UtenteDto;
  logoRivenditore: string;


  isSubmitting = false;
  codiceLicenza = '';

  constructor(private authService: AuthService,
    private rivenditoreService: RivenditoreService,
    private licenzaService: LicenzaService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.user = this.authService.getUser();
    this.logoRivenditore = this.rivenditoreService.getLogoUrl(this.user.Licenza.IdRivenditore);
  }

  attivaCodiceLicenza(f: NgForm) {
    if (!f.valid) {
      this.toastr.warning('Correggere gli errori e riprovare');
      return;
    }

    this.isSubmitting = true;

    this.licenzaService.attivaLicenza(this.user.Id, this.codiceLicenza).subscribe((response: ActivateLicenseStatus) => {
      this.isSubmitting = false;
      switch (response) {
        case ActivateLicenseStatus.UtenteNonValido:
          this.toastr.warning('Utente non valido');
          break;

        case ActivateLicenseStatus.LicenzaNonValida:
          this.toastr.warning('Codice licenza non valido');
          break;

        case ActivateLicenseStatus.LicenzaGiaAttiva:
          this.toastr.warning('Codice licenza già utilizzato');
          break;
        case ActivateLicenseStatus.LicenzaAttivata:
          this.toastr.warning('Licenza attivata');
          this.authService.refreshUser().subscribe(u => {
            this.authService.setUser(u);
            this.user = u;
          })
          break;

        case ActivateLicenseStatus.UtenteHaLicenzaAttiva:
          this.toastr.warning('Hai già una licenza attiva');
          break;

        case ActivateLicenseStatus.UtenteNonHaLicenzaAttiva:
          this.toastr.warning('La licenza non è attiva');
          break;
      }
    });
  }
}
